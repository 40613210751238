import React from "react"

import Layout from "../components/layout"
import Head from "../components/head"

const IndexPage = () => (
  <Layout>
    <Head title="Home" />
    <h1>Home of AMARE</h1>
    <h3>
      Ovo je naša početna stranica. Bavimo se trgovinom na malo suvenirima i
      poklonima, te iznajmljivanjem apartmana.
    </h3>
  </Layout>
)

export default IndexPage
